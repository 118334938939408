<template>
  <div class="parnet-product-ingredients">
    <h2 class="title-parnet mt-1">Lista de Ingredientes</h2>
    <div class="list-ingredients scroll">
      <Ingredient :activate="true" />
      <Ingredient :activate="false" />
      <Ingredient :activate="false" />
      <Ingredient :activate="false" />
      <Ingredient :activate="false" />
      <Ingredient :activate="false" />
      <Ingredient :activate="false" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Ingredient: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Ingredient" */ '@/modules/cms/parts/ingredients/parnetProduct/Ingredient.vue'
      )
    ),
  },
  setup() {
    const ingredients = [];

    return {
      ingredients,
    };
  },
};
</script>
